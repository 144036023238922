/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { XHRHttpRequest } from './core/XHRHttpRequest';

import { AppointmentService } from './services/AppointmentService';
import { AssistPhoneNumberService } from './services/AssistPhoneNumberService';
import { AuthService } from './services/AuthService';
import { CloudFileService } from './services/CloudFileService';
import { DefaultService } from './services/DefaultService';
import { DoctorNoteService } from './services/DoctorNoteService';
import { InstitutionService } from './services/InstitutionService';
import { MedStaffService } from './services/MedStaffService';
import { OneTimeAuthService } from './services/OneTimeAuthService';
import { PaymentService } from './services/PaymentService';
import { PdfService } from './services/PdfService';
import { ProjectSettingService } from './services/ProjectSettingService';
import { QuestionerService } from './services/QuestionerService';
import { QuestionerAnswerService } from './services/QuestionerAnswerService';
import { ScreenService } from './services/ScreenService';
import { SmsService } from './services/SmsService';
import { TranslationsService } from './services/TranslationsService';
import { UserService } from './services/UserService';
import { UserAdminService } from './services/UserAdminService';
import { WebhookService } from './services/WebhookService';
import { WellKnownService } from './services/WellKnownService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class GerasApi {

    public readonly appointment: AppointmentService;
    public readonly assistPhoneNumber: AssistPhoneNumberService;
    public readonly auth: AuthService;
    public readonly cloudFile: CloudFileService;
    public readonly default: DefaultService;
    public readonly doctorNote: DoctorNoteService;
    public readonly institution: InstitutionService;
    public readonly medStaff: MedStaffService;
    public readonly oneTimeAuth: OneTimeAuthService;
    public readonly payment: PaymentService;
    public readonly pdf: PdfService;
    public readonly projectSetting: ProjectSettingService;
    public readonly questioner: QuestionerService;
    public readonly questionerAnswer: QuestionerAnswerService;
    public readonly screen: ScreenService;
    public readonly sms: SmsService;
    public readonly translations: TranslationsService;
    public readonly user: UserService;
    public readonly userAdmin: UserAdminService;
    public readonly webhook: WebhookService;
    public readonly wellKnown: WellKnownService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = XHRHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '2.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.appointment = new AppointmentService(this.request);
        this.assistPhoneNumber = new AssistPhoneNumberService(this.request);
        this.auth = new AuthService(this.request);
        this.cloudFile = new CloudFileService(this.request);
        this.default = new DefaultService(this.request);
        this.doctorNote = new DoctorNoteService(this.request);
        this.institution = new InstitutionService(this.request);
        this.medStaff = new MedStaffService(this.request);
        this.oneTimeAuth = new OneTimeAuthService(this.request);
        this.payment = new PaymentService(this.request);
        this.pdf = new PdfService(this.request);
        this.projectSetting = new ProjectSettingService(this.request);
        this.questioner = new QuestionerService(this.request);
        this.questionerAnswer = new QuestionerAnswerService(this.request);
        this.screen = new ScreenService(this.request);
        this.sms = new SmsService(this.request);
        this.translations = new TranslationsService(this.request);
        this.user = new UserService(this.request);
        this.userAdmin = new UserAdminService(this.request);
        this.webhook = new WebhookService(this.request);
        this.wellKnown = new WellKnownService(this.request);
    }
}

